
import { DxTabPanel, DxItem } from 'devextreme-vue/tab-panel';

import HabilidadTab from './ordentrabajoservicio.vue';



export default {
  components: {
    DxTabPanel, DxItem, HabilidadTab
  },
  props: {
    masterDetailData: {
      type: Object,
      default: () => ({}),
    },
  },
};
